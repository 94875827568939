.news {
	.image {
		max-height: 360px !important;

		img {
			max-width: 480px;
			max-height: 360px !important;
		}
		@media (max-width: 768px) {
			img {
				max-width: 100%;
			}
		}
	}

	.title {
		margin-top: 25px;
		font-size: 32px !important;
		color: #515a62 !important;
		font-family: 'Muli', sans-serif;
		font-weight: bold !important;
		text-transform: none !important;

		@media screen and (max-width: 1366px) {
			font-size: 20px !important;
		}
	}
}
