.main {
    font-size: 13px;
    font-family: "Muli",sans-serif !important;
    .title {
        font-family: "Muli",sans-serif !important;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0px;
    }

    .donor {
        font-family: 'Muli', sans-serif;
        font-size: 14px;
        text-decoration: underline;
        color: #1779ba;
    }

    .statistics {
        //text-decoration: underline;
        display: inline-block;
        color: #1779ba;
        margin-bottom: 10px;
        font-size: 14px;
    }
    

    .categories{
        margin-top: 1rem;
        font-size: 16px;
    }

    .item {
        display: flex;
        flex-direction: row;

        .title {
            text-transform: capitalize;
            display: inline-block;
            min-width: 100px;
            color: #d5d5d5;
            font-weight: bold;
            padding-right: 10px;
            text-align: right;
        }
    }

    .partnership{
        margin-top: 20px;
        .title{
            font-size: 16px;
        }
    }
}