.search {
    display: inline-block;
    min-width: 386px;
    border-style: solid;
    border-width: 1px;
    border-radius: 25px;
    margin-right: 0px;
    border-color: #515A62;

    .inputGroup {
        border: none;

        .icon {
            background-color: transparent;
            color: #515A62;
            border: none;
            padding: 10px 5px 10px 10px;
        }

        .input {
            border: none;
            background-color: transparent;
            color: #515A62;
            padding: 5px;
            font-family: Oxygen, Verdana, Sans-serif;
            transition: 1s ease-in-out;
            outline: none !important;

            &:focus {
                //transition: 1s ease-in-out;
                //width: 300px !important;
                outline: none !important;
                box-shadow: none;
            }
        }
    }
}

.searchOff{
    display: inline-block;
    min-width: 386px;
    border-style: solid;
    border-width: 1px;
    border-radius: 25px;
    margin-right: 0px;
    //border-color: white;
    background-color: white;
    .inputGroup {
        border: none;

        .icon {
            background-color: transparent;
            color: black;
            border: none;
            padding: 10px 5px 10px 10px;
        }

        .input {
            border: none;
            background-color: transparent;
            color: black;
            padding: 5px;
            font-family: Oxygen, Verdana, Sans-serif;
            transition: 1s ease-in-out;
            outline: none !important;

            &:focus {
                //transition: 1s ease-in-out;
                //width: 300px !important;
                outline: none !important;
                box-shadow: none;
            }
        }
    }
}