.loadForm{
    opacity: 0.6;
}

.group {
    .title {
        font-family: "Muli", sans-serif !important;
        font-size: 16px !important;
        font-weight: bold !important;
        color: #454548 !important;

    }

    .text {
        font-family: "Muli", sans-serif !important;
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 3rem;
        margin: 0 0 1.23077rem;
        padding: 0.61538rem;
        border: 1px solid #515A62;
        border-radius: 0;
        background-color: #fefefe;
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
        font-family: inherit;
        font-size: 16px;
        font-weight: normal;
        color: #0a0a0a;
        transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
        appearance: none;
        &:focus {
            outline: none;
            border: 1px solid #27282C;
            background-color: #fefefe;
            box-shadow: 0 0 5px #515A62;
            transition: box-shadow 0.5s,border-color 0.25s ease-in-out;
        }
    }
}

.btn {
    font-size: 16px;
    margin-right: 2.5% !important;
    font-weight: bold;
    width: auto;
    margin-bottom: 7px;
    box-shadow: #afafaf 2px 2px 2px;
    //padding: 8px 15px !important;
    height: 34px;
    text-transform: unset;
    color: #7a7489;
    background-color: #f8f6f9 !important;
    border: 0;
    padding: 0 15px;

    &:hover {
        background-color: #7a7489 !important;
        color: #f8f6f9 !important;
    }
}