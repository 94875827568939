.title{
    font-size: 32px;
    color: #515A62;
    font-family: "Muli",sans-serif;
    font-weight: bold !important;
    text-transform: none !important;
}

.description{
    font-size: 16px !important;
    font-family: "Source Sans Pro","Helvetica",Arial,Sans-serif;
    margin-top: -15px !important;
    margin-bottom: 20px !important;
}

.categories{
    display: flex;
    width: 100%;
    margin-top: 25px !important;
    margin-bottom: 25px;
    a{
        padding-left: 5px;
    }
}