.option{
    //background-color: black;
    margin: 5px;
    //background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    padding: 5px;
    .icon{
        //background-color: black;
        color: white !important;
        margin: 1px;
        font-size: 25px;
    }
    cursor: pointer;
}