/* Color Overrides */

/* $primary: #6ab42f;
$nav-pills-link-active-color: $primary;
$primary-light: rgba($primary, 0.2); */

/* This theme colors */

$theme-odd-even-color: #efefef;
$theme-review-data-color: blue;

/* padding overrides */
$input-padding-y: 2px;
$input-padding-x: 2px;
$input-font-family: 'Courier New';
$input-border-radius: 2px;

/* Fonts */
$font-size-base: 0.9rem;
$font-family-sans-serif: 'Oxygen', Verdana, sans-serif;

/*spaces*/
$spacer: 0.4rem;
$modal-header-padding: 10px;
$modal-md: 50vw;

/*pagination*/
$pagination-active-bg: #e6e6e6 !important;
$pagination-active-color: #000 !important;
$pagination-active-border-color:#e6e6e6 !important;

$pagination-hover-bg:               #e6e6e6 !important;
$pagination-hover-border-color:     #e6e6e6 !important;
$pagination-hover-color:     #000  !important;
$pagination-color:                 #000;
$pagination-border-radius:          0 !important;

/* placeholder */
$placeholder-color: rgb(178, 178, 178)!important;


