.news {
    .image {
        span {
            width: 480px;
            height: 360px !important;
            display: flex !important;
        }

        .placeimage {
            width: 480px;
            height: 360px !important;
        }
    }

    .title {
        margin-top: 25px;
        font-size: 32px !important;

        @media screen and (max-width: 1366px) {
            font-size: 20px !important;
        }
    }
}