.title{
    font-size: 32px;
    color: #515A62;
    font-family: "Muli",sans-serif;
    font-weight: bold !important;
    text-transform: none !important;
}
.btn{
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}