.title{
    font-size: 32px;
    color: #515A62;
    font-family: "Muli",sans-serif;
    font-weight: bold !important;
    text-transform: none !important;
}

.description{
    margin-bottom: 1rem;
    font-size: inherit;
    line-height: 1.6;
    text-rendering: optimizeLegibility;
    font-size: 20px;
    font-family: "Source Sans Pro","Helvetica",Arial,Sans-serif !important;

}