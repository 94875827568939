.footer {
	font-size: clamp(0.65rem, 1.5vw, 0.75rem);
	text-align: center;
	background-color: #f7f6f8;

	.supportUs {
		color: white;
		background-color: #A01F19;
		margin: 10px;
		border-radius: 15px 15px 0px 0px;
		margin-bottom: 0;

		.suTitle {
			font-family: "Muli", sans-serif;
			font-size: 30px;
			color: #F8F8F9;
			text-align: center;
			padding: 10px
		}

		.suDescription {
			color: #F8F8F9;
			font-family: "Source Sans Pro", "Helvetica", Arial, Sans-serif;
			font-weight: bold;
			text-align: center;
			padding: 10px;
			font-size: 16px;
		}

		.suButton {
			text-align: center;
			padding: 10px;
			padding-left: 0;
			padding-right: 0;
			width: 200px;
			margin-top: 8px;
			margin: 0 auto !important;

			button {
				background: #FFFFFF;
				color: #A02019;
				border-radius: 25px;
				width: 100%;
				height: 35px;
				font-weight: bold;
				font-family: "Muli", sans-serif;
				font-size: 16px;
				border: none;
			}
		}

		@media screen and (max-width: 1200px) {
			border-radius: 0;
			margin: 0 !important;
		}
	}

	.About {
		text-align: start;

		.title {
			font-size: 20px;
			font-weight: bold;
			color: #27282C;
		}

		.description {
			p {
				margin-bottom: 1rem;
				font-size: inherit;
				line-height: 1.6;
				text-rendering: optimizeLegibility;
			}
		}

		.open {
			font-weight: bold;
		}

		@media screen and (max-width: 1199.9px) {
			text-align: center;
			margin-top: 20px;
		}
	}

	.optionsCol {
		.options {
			text-align: start;
			font-weight: bold;

			.option {
				cursor: pointer;
				padding: 5px;
				color: #000000;
				text-decoration: none;
			}

			.search{
				cursor: pointer;
				color: #000000;
				text-decoration: none;
			}
		}
		@media screen and (max-width: 1199.9px) {
			display: none;
		
		}
	}

	.btnMakeDonation {
		background-color: #FFFFFF;
		border-radius: 25px;
		border: 2px solid #A01F19;
		color: #A01F19 !important;
		box-shadow: 0px 3px 2px #A01F19 !important;
		text-align: center;
		cursor: pointer;
		width: 100%;
		max-width: 290px;
		margin: 0 auto !important;
		font-weight: bold;
		font-family: "Muli", sans-serif;
		height: 50px;
		font-size: 16px;

		.icon {
			margin-right: 5px;
			font-size: 25px;
		}

		&:active {
			background-color: #A01F19 !important;
			color: #FFFFFF !important;
			border: 2px solid #A01F19;
		}
	}

	.btnJoinMailing {
		background-color: #FFFFFF;
		border-radius: 25px;
		border: 2px solid #4F5A65 !important;
		color: #4F5A65 !important;
		box-shadow: 0px 3px 2px !important;
		text-align: center;
		cursor: pointer;
		width: 100%;
		max-width: 290px;
		margin-top: 8px;
		margin: 0 auto !important;
		margin-top: 8px !important;
		margin-bottom: 8px !important;

		font-weight: bold;
		font-family: "Muli", sans-serif;
		height: 50px;
		font-size: 16px;

		.icon {
			margin-right: 5px;
			font-size: 25px;
		}

		&:active {
			background-color: #4F5A65 !important;
			color: #FFFFFF !important;
		}
	}

	.btnUp {
		display: none;
		width: 50px;
		height: 50px;
		text-align: center;
		margin-bottom: 10px;
		background-color: #515A62;
		border: none;

		.icon {
			//margin: 1px;
			font-size: 25px;
		}

		@media screen and (max-width: 1199.9px) {
			display: flex;
			align-items: center;
		}

		&:active {
			background-color: #4F5A65 !important;
			color: #FFFFFF !important;
		}
	}
}