.card {
    display: flex;
    flex-direction: row;
    width: 100%;

    margin-top: 10px;
    margin-bottom: 10px;

    .image {
        width: 147px !important;
        height: 110px;
        color: rgb(178, 178, 178)!important;
        span{
            width: 100% !important;
            height: 100% !important;
            display: flex !important;
        }
        .placeimage {
            width: 100%;
            height: 110px !important;
        }
    }

    .content {
        width: 60%;
        //padding-left: 12px !important;
        padding: 0px 10px 10px 12px;
        .title {
            text-overflow: ellipsis;
            line-height: 1.3;
            padding-top: 2px;
            font-size: 16px;
            font-family: "Muli", sans-serif;
            font-weight: bold !important;
            text-transform: none !important;
            line-height: inherit;
            color: rgb(178, 178, 178)!important;
            cursor: pointer;
        }

        .description {
            color: rgb(178, 178, 178)!important;
            font-size: 14px;
            line-height: 1.3;
        }
    }
}