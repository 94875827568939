.skip_main_content {
	background: black;
	z-index: 9999;
	height: 30px;
	padding: 8px;
	position: absolute;
	transform: translateY(-1000%);
	transition: transform 0.3s;
}
.skip_main_content:focus {
	transform: translateY(0%);
}
