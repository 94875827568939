.btn{
    background-color: #FFFFFF;
    border-radius: 25px;
    border: 2px solid #4F5A65 !important;
    color: #4F5A65 !important;
    box-shadow: 0px 3px 2px !important;
    text-align: center;
    cursor: pointer;
    width: 200px;
    max-width: 290px;
    /* margin-top: 8px;
    margin: 0 auto !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important; */
    margin: 20px auto !important;

    font-weight: bold;
    font-family: "Muli", sans-serif;
    height: 50px;
    font-size: 16px;

    &:active {
        background-color: #4F5A65 !important;
        color: #FFFFFF !important;
    }

    &:hover {
        background-color: #FFFFFF !important;
        color: #4F5A65 !important;
    }
}