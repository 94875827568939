.mainTimeMark{
    //margin-top: 3px;
.button {
    cursor: pointer;
    display: flex;
    font-size: 15px;
    padding: 10px;
    justify-content: space-between !important;
    align-items: center;
    background-color: transparent;
    border-color: transparent;
    width: 100%;
    color: black;

    border-radius: 0px !important;
    padding: 0px !important;

    &:hover {
        //font-size: 15px;
        font-weight: bold;
        background-color: transparent !important;
        border-color: transparent !important;
        color: black;
    }

    .icon {
        width: 10px;
        height: 10px;
        margin: 10px;
        color: #4e6cb4;

    }

    .iconPlay {
        width: 17px;
        height: 17px;
        margin: 10px;
        color: #4e6cb4;
    }

    .iconPlus {
        width: 15px;
        height: 15px;
        margin-right: 10px;
        z-index: 100;
    }


}

.timeMark {
    width: 100%;
    /* display: flex;
    justify-content: space-between; */
    align-items: center;
    text-align: start;
}

.active {
    font-weight: bold;
    background-color: #D9DBDD !important;

    &:hover {
        //font-size: 15px;
        font-weight: bold;
        background-color: #D9DBDD !important;
        border-color: #D9DBDD !important;
    }

}
}