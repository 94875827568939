.btnMakeDonation {

    display: flex;
    justify-content: center;
    align-items: center;
    
    line-height: 0px !important;

    background-color: #FFFFFF;
    border-radius: 25px;
    border: 2px solid #A01F19;
    color: #A01F19 !important;
    box-shadow: 0px 3px 2px #A01F19 !important;
    text-align: center;
    cursor: pointer;
    width: 100%;
    max-width: 290px;
    margin: 0 auto !important;
    font-weight: bold;
    font-family: "Muli", sans-serif;
    //height: 50px;
    font-size: 16px;
    padding: 0px 10px !important;

    .icon {
        margin-right: 5px;
        font-size: 25px;
    }

    &:active {
        background-color: #A01F19 !important;
        color: #FFFFFF !important;
        border: 2px solid #A01F19;
    }

    &:hover {
        background-color: #FFFFFF !important;
        border-radius: 25px !important;
        border: 2px solid #A01F19 !important ;
    }

   /*  @media (min-width: 1208px) {
        
    } */
}

@media (max-width: 1208px) {
    .btnMakeDonation {
        margin-top: 10px !important;
        padding: 10px 10px !important;
    }
}