.partnership{
    margin-top: 20px;
    .title{
        font-size: 16px;
    }
    .sponsors{
        margin-top: 10px;
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: center;
    }
}