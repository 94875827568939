.card {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none !important;
    .image {
        width: 147px !important;
        height: 110px;
        cursor: pointer;
        span{
            display: inline !important;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .content {
        width: 60%;
        //padding-left: 12px !important;
        padding: 0px 10px 10px 12px;
        .title {
            text-overflow: ellipsis;
            line-height: 1.3;
            font-size: 16px;
            font-family: "Muli", sans-serif !important;
            font-weight: bold !important;
            text-transform: none !important;
            line-height: inherit;
            color: #27282C;
            cursor: pointer;
        }

        .description {
            color: #27282B;
            font-size: 14px;
            line-height: 1.3;
            text-overflow: ellipsis !important;
            font-family: "Source Sans Pro", "Helvetica", Arial, Sans-serif !important;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}