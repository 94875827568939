.brand {
    cursor: pointer;

    .logo {

        height: 70px;

        @media screen and (max-width: 331px) {
            height: 50px;
        }
    }
}

.navdd {
    //background-color: #000;
    position: unset !important;

    a {
        &:hover {
            color: #C1272D !important;
        }
    }
}

.navOptions {
    display: flex;
    flex-direction: row;

    .otherOptions {
        margin-right: 5px;
        display: flex;
        flex-direction: row;
        .searchbar{
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
                display: none;
            }
        }
        .socialOptions {

            @media (max-width: 1208px) {
                display: none;
            }
        }
    }
}

.otherOptionsOffcanva {
    display: flex;
    flex-direction: column;

    //margin-right: 10px;
    //margin-bottom: 10px;
    .searchbar{
        @media (min-width: 768px) {
            display: none;
        }
    }
    .socialOptions {

        @media (min-width: 1208px) {
            display: none;
        }
    }
}

.lateralBar {
    .closeBtn {
        background-color: transparent !important;
        padding: 0px !important;
        border: none !important;
        border-radius: 0px !important;
        font-size: 20px;

        &:hover {
            background-color: transparent !important;
            border: none !important;
        }
    }

    background: #515A62;
    font-size: 16px;
    font-family: "Muli",
    sans-serif;
    font-weight: bold !important;
    border: none;
    color: white;
}

.phoneSearch {
    @media (min-width: 768px) {
        display: none;
    }

    width: 100%;
    display: flex;
    justify-content: center;
}