.videoplaceholder {
    //height: 450px;
    width: 100%;
    display: flex !important;
    margin-bottom: 10px;
    color: rgb(178, 178, 178) !important;
    aspect-ratio: 4/3;

    span {
        width: 100% !important;
        height: 100% !important;
        display: flex !important;
    }

    .placeimage {
        width: 100%;
        height: 110px !important;
    }

    @media screen and (max-width: 768px) {
        height: 300px;
    }
}

.segment {
    color: rgb(178, 178, 178) !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap : 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    .title{
        height: 20px !important;
    }
    .square {
        height: 55px;
        width: 55px;
        display: flex !important;
        span {
            width: 100% !important;
            height: 100% !important;
            display: flex !important;
        }
    }
}

.main {
    font-size: 13px;
    font-family: "Muli", sans-serif !important;

    .title {
        .placetitle {
            color: rgb(178, 178, 178) !important;
            font-family: "Muli", sans-serif !important;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 0px;
        }
    }

    .donor {
        font-family: 'Muli', sans-serif;
        font-size: 14px;
        text-decoration: underline;
        color: #1779ba;
    }

    .statistics {
        text-decoration: underline;
        color: #1779ba;
        margin-bottom: 10px;
    }

    .categories {
        margin-top: 1rem;
        font-size: 16px;
        color: rgb(178, 178, 178) !important;
    }

    .item {
        display: flex;
        flex-direction: row;

        .title {
            text-transform: capitalize;
            display: inline-block;
            min-width: 100px;
            color: #d5d5d5;
            font-weight: bold;
            padding-right: 10px;
            text-align: right;
            color: rgb(178, 178, 178) !important;
        }
    }

    .itexts {
        display: grid;
        gap: 5px;
        color: rgb(178, 178, 178) !important;

        .itext {

            display: flex;
            height: 40px;

            color: rgb(178, 178, 178) !important;

            .placetitle {
                height: 100%;
                color: rgb(178, 178, 178) !important;
            }
        }
    }

    .options {
        margin-top: 10px;

        .buttons {

            display: flex;
            gap: 20px;

            .button {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 7px;
                box-shadow: #afafaf 2px 2px 2px;
                height: 34px;
                text-transform: unset;
                color: #7a7489;
                background-color: #f8f6f9 !important;
                border: 0;
                padding: 0 15px;

                &:hover {
                    background-color: #7a7489 !important;
                    color: #f8f6f9 !important;
                }
            }

            .btnActive {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 7px;
                box-shadow: #afafaf 2px 2px 2px;
                height: 34px;
                text-transform: unset;
                border: 0;
                padding: 0 15px;
                background-color: #7a7489 !important;
                color: #f8f6f9 !important;
            }

            @media screen and (max-width: 768px) {
                justify-content: center;

                .button {
                    font-size: 13px;
                }

                .btnActive {
                    font-size: 13px;
                }
            }
        }

        .information {
            margin-top: 10px;
            font-size: 16px !important;
            height: 408px;
            color: rgb(178, 178, 178) !important;

            .active {
                display: block;
            }

            .desactive {
                display: none;
            }

            .keywords {
                font-size: 14px;
                text-decoration: underline;
                color: #1779ba;
                cursor: pointer;
            }
        }
    }

    .partnership {
        .title {
            font-size: 16px;
        }
    }



}