.item {
    //border: 0;
    border-radius: 0 !important;
    background-color: #484848;
    //margin-bottom: 10px;

    .header {
        border-radius: 0 !important;

        button {
            border-radius: 0 !important;
            background-color: white;
            font-weight: bold;
            text-transform: capitalize;
            padding: 10px;
            font-size: 16px !important;

            &:hover {
                background-color: #e6e6e6;
            }

            &:focus {
                border-color: transparent !important;
                box-shadow: none !important;
            }

            /*  &:not(.collapsed){
                background-color: #e6e6e6;
                color : black;
            } */
        }

        background-color: black;
    }

    .body {
        background-color: white;
        /* max-height: 400px !important;
        overflow-y: scroll; */
        max-height: 355px;
        overflow-y: auto;
        color: black;
        padding: 3px !important;

        .button {
            cursor: pointer;
            display: flex;
            font-size: 14px;
            margin-bottom: 5px;
            padding: 10px;
            justify-content: space-between !important;
            align-items: center;
            background-color: transparent;
            border-color: transparent;
            width: 100%;

            &:hover {
                //font-size: 15px;
                font-weight: bold;
            }

            .icon {
                width: 5px;
                height: 5px;
                margin-right: 10px;

            }

            .iconPlus {
                width: 15px;
                height: 15px;
                margin-right: 10px;
            }
        }
    }
}

.moreInfo {
    border: 0px !important;
    border-bottom: 1px solid #e6e6e6 !important;
}