.options {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    .title {
        font-size: 16px;
        font-family: "Muli", sans-serif;
        font-weight: bold !important;
        text-transform: none !important;
        @media (max-width: 768px) {
            display: none;
        }
    }
    .option{
        text-align: center !important;
        .value{
            text-align: center !important;
            width: 40px !important;
            input{
                width: 100%;
                border: none !important;
                box-shadow: none !important;
                height: 12px !important;
                line-height: 12px;
                padding: 0 !important;
                font-size: 10px;

                &:focus{
                    outline: none !important;
                }
            }
        }
        .btn{
            padding: 0 !important;
            cursor: pointer;
            img{
                width: 33px !important;
                height: 33px !important;
            }
            .icon{
                font-size: 25px !important;
            }
            width: 33px !important;
            height: 33px !important;
        }
        .btnStyle{
            background-color: white;
            color: black;
            border: 1px solid black;
        }

    }
    .option2{
        text-align: center !important;
        .value{
            text-align: center !important;
            width:33px !important;
            input{
                width: 100%;
                border: none !important;
                box-shadow: none !important;
                height: 12px !important;
                line-height: 12px;
                padding: 0 !important;
                font-size: 10px;

                &:focus{
                    outline: none !important;
                }
            }
        }
        .btn{
            padding: 0 !important;
            cursor: pointer;
            img{
                width: 33px !important;
                height: 33px !important;
            }
            .icon{
                font-size: 25px !important;
            }
            width: 33px !important;
            height: 33px !important;
        }
        .btnStyle{
            background-color: white;
            color: black;
            border: 1px solid white;
            margin-top: 22px;
        }

    }
    /* @media screen and (max-width: 1020px) {
        justify-content: center;
        
    } */
}