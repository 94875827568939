.shareLink {
    .title {
        font-size: 19.5px;
        font-weight: bold;
    }

    .value {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        input {
            width: 95%;
            height: 30px;
            border-radius: 5px 0px 0px 5px;
            border: 1px solid #d1d1d1;
        }

        .btn {
            text-align: center;
            height: 30px;
            width: 30px;
            padding: 0;
            border-radius: 0 5px 5px 0;
            background-color: #d1d1d1;
            color: black;
            border: 1px solid #d1d1d1;

            &:hover {
                background-color: #9c9c9c;
                color: black;
            }
        }
    }
}

.socialLinks {
    margin: 16px 0 16px 0;

    .title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 16px;
    }

    .value {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .btn {
            cursor: pointer;
            border-radius: 5px;
            .icon {
                color: white;
                font-size: 30px;
                width: 30px;
                height: 30px;
            }

            padding: 5px;
            margin: 0 8px 0 0;
        }
    }
}
.embedCode{
    width: 100%;
    margin-bottom: 16px;
    .title {
        font-size: 19.5px;
        font-weight: bold;
    }
    .value{
        textarea{
            width: 100%;
        }
    }
}