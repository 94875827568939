.main {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem !important;
    .categories {
        margin-top: 1rem;
        font-size: 16px;
        display: inline-flex;
        gap: 12px;
        flex-wrap: wrap;
        .option {
            font-size: 14px;
            text-decoration: underline;
            color: #1779ba;
            cursor: pointer;
            font-family: "Source Sans Pro", "Helvetica", Arial, Sans-serif !important;
        }
    }
}