.title {
	font-size: 20px;
	font-weight: bold;
	vertical-align: middle;
	font-family: 'Muli', sans-serif !important;
	margin: 20px 0;
}
.exploreBtn {
	display: flex;
	justify-content: flex-start;
}

.options {
	.title {
		font-size: 24px;
		font-weight: bold;
		color: #515a62 !important;
	}
}

.videoplaceholder {
	//height: 450px;
	width: 100%;
	display: flex !important;
	margin-bottom: 10px;
	color: rgb(178, 178, 178) !important;
	aspect-ratio: 4/3;
	span {
		width: 100% !important;
		height: 100% !important;
		display: flex !important;
	}

	.placeimage {
		width: 100%;
		height: 110px !important;
	}

	@media screen and (max-width: 768px) {
		height: 300px;
	}
}
