.commentRow {
    display: table-row;

    .column1 {
        display: table-cell;
        width: 25%;
        padding: 5px;
        padding: 5px;
        padding-left: 5px;
        padding-bottom: 20px;
        .richmedia_user_comment {
            font-family: "Muli",sans-serif !important;
            font-size: 16px;
            font-weight: bold;
            color: #454548 !important;
        }
        .richmedia_date_comment {
            font-family: "Source Sans Pro","Helvetica",Arial,Sans-serif !important;
            font-size: 14px;
        }
    }

    .column2 {
        display: table-cell;
        width: 75%;
        padding: 5px;
        padding: 5px;
        padding-left: 5px;
        padding-bottom: 20px;
        .richmedia_body_comment {
            font-family: "Source Sans Pro","Helvetica",Arial,Sans-serif !important;
            font-size: 16px;
        }

    }
}