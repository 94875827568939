.options {
    margin-top: 10px;

    .buttons {
        display: inline-block;
        width: 100%;
        gap: 20px;

        .button {
            font-size: 16px;
            margin-right: 2.5% !important;
            font-weight: bold;
            width: auto;
            margin-bottom: 7px;
            box-shadow: #afafaf 2px 2px 2px;
            //padding: 8px 15px !important;
            height: 34px;
            text-transform: unset;
            color: #7a7489;
            background-color: #f8f6f9 !important;
            border: 0;
            padding: 0 15px;

            &:hover {
                background-color: #7a7489 !important;
                color: #f8f6f9 !important;
            }
        }

        .btnActive {
            background-color: #7a7489 !important;
            color: #f8f6f9 !important;
        }
    }

    .information {
        margin-top: 10px;
        font-size: 16px !important;
        max-height: 500px;
        overflow-y: auto;

        .active {
            display: block;
        }

        .desactive {
            display: none;
        }

        .keywords {
            font-size: 14px;
            color: #1779ba;
            cursor: pointer;

            ul {
                display: inline-block;
                list-style-type: none;
                margin: 0;
                vertical-align: top;
                padding-left: 0;

                li {
                    float: left;

                    a {
                        font-size: 16px !important;
                        font-family: 'Source Sans Pro', 'Helvetica', Arial, Sans-serif !important;
                        color: #1779ba !important;
                        text-decoration: underline !important;
                        display: inline-block;
                        margin-right: 10px;
                        margin-bottom: 16px;
                        cursor: pointer;
                        line-height: inherit;
                    }
                }
            }
        }
    }

    .comments {
        display: table;
        width: 98%;
        margin: 0 auto;
    }
}