.browseby {
    .title {
        font-size: 24px;
        font-weight: bold;
        color: #515A62 !important;
    }

    .links {
        .option{
            display: table !important;
            width: 100% !important;
            .link {
                color: #4f5A65 !important;
                font-size: 16px !important;
                font-family: "Source Sans Pro","Helvetica",Arial,Sans-serif !important;
            }
            @media screen and (max-width: 992px) {
                margin-left: 0;
                display: inline-block !important;
                width: auto !important;
                margin-right: 10px;
            }
        }
        //gap: 5px;
        
    }
}